import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  Filter,
  TextInput,
  DateInput,
  Button,
  BulkExportButton,
  BulkUpdateButton,
  SimpleForm,
  Toolbar,
  SaveButton,
  Edit,
  EditButton,
  useNotify,
  SelectInput,
  required,
  FormDataConsumer,
} from 'react-admin';
import LaunchIcon from '@material-ui/icons/Launch';
import React from 'react';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import { __setupAuth } from '../contexts/AuthContext';
import downloadGoalPageStats from '../lib/dowloadGoalPageStats';

// eslint-disable-next-line no-empty-function
const auth = __setupAuth({ setPictureUrl: () => {} });
const GoalPageEditToolbar = (props) => (
  //removes default delete option from toolbar
  <Toolbar { ...props }>
    <SaveButton />
  </Toolbar>
);

const errorHandler = (error, notify) => {
  if (error.status === 'Conflict') {
    notify(`Error: Name and Slug must be Unique`);
  } else {
    notify(`Error: ${ error.body || error.message }`);
  }
};

export const GoalPageEdit = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    errorHandler(error, notify);
  };

  return (
    <Edit onFailure={ onFailure } undoable={ false } { ...props }>
      <SimpleForm redirect='edit' toolbar={ <GoalPageEditToolbar /> }>
        <TextInput disabled source='id' />
        <TextInput source='slug' />
        <TextInput source='goal' />
        <DateInput disabled source='startDate' />
        <DateInput disabled source='endDate' />
        <SelectInput
          choices={ [
            { id: 'REQUESTED', name: 'Requested' },
            { id: 'APPROVED', name: 'Approved' },
            { id: 'REJECTED', name: 'Rejected' },
            { id: 'ARCHIVED', name: 'Archived' },
          ] }
          source='status'
        />
        <FormDataConsumer>
          { ({ formData, ...rest }) => (
            <div>
              { (formData.status === 'REJECTED' || !!formData.rejectedReason) && (
                <TextInput
                  source='rejectedReason'
                  validate={ required() }
                  { ...rest }
                />
              ) }
            </div>
          ) }
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

/**
 * @param {Object} props
 * @returns 'APPROVED', 'SENT' or null based on what the next status (for bulk action button)
 *  should be based on the current status filter.
 */
export const showBulkApproveButton = (props) => props.filterValues?.status === 'REQUESTED';

const GoalPageListBulkActionButtons = (props) => (
  <div>
    { showBulkApproveButton(props) && (
      <BulkUpdateButton
        data={{ status: 'APPROVED' }}
        icon={ <ThumbUpIcon /> }
        label='Approve Selected'
        { ...props }
      />
    ) }
    <BulkExportButton { ...props } />
  </div>
);

const GoalPageFilter = (props) => {
  const statuses = [
    { id: 'REQUESTED', name: 'Requested' },
    { id: 'APPROVED', name: 'Approved ' },
    { id: 'REJECTED', name: 'Rejected' },
    { id: 'ARCHIVED', name: 'Archived' },
  ];
  return (
    <Filter { ...props }>
      <SelectInput alwaysOn choices={ statuses } id='status' source='status' />
      <TextInput alwaysOn label='Name' source='user.fullName:contains' />
      <TextInput alwaysOn label='Slug' source='slug:contains' />
    </Filter>
  );
};

const GoalPageList = (props) => (
  <List
    { ...props }
    bulkActionButtons={ <GoalPageListBulkActionButtons /> }
    filters={ <GoalPageFilter /> }
    sort={{ field: 'id', order: 'DESC' }}
  >
    <Datagrid>
      <TextField source='id' />
      <TextField label='admin email' source='users.email' />
      <TextField source='slug' />
      <FunctionField
        label='page'
        render={ (record) => (
          <span>
            { record.name }
            <a
              className={ record.slug && record.slug !== '' ? '' : 'hidden' }
              href={ `${ process.env.REACT_APP_URL }/goal-page/${ record.slug }` }
              rel='noreferrer'
              target='_blank'
            >
              <LaunchIcon fontSize='small' />
            </a>
          </span>
        ) }
      />
      <TextField source='goal' />
      <TextField showTime source='status' />
      <FunctionField
        label='Users and Stats'
        render={ (record) => (
          <Button
            className={ record.slug && record.slug !== '' ? '' : 'hidden' }
            label='Download'
            onClick={ async () => await downloadGoalPageStats({ auth, goalPage: record.slug }) }
          >
            <i className='fa fa-arrow-circle-o-down' />
          </Button>
        ) }
      />
      <EditButton { ...props } />
    </Datagrid>
  </List>
);

export default GoalPageList;
